import React, { useState, useEffect } from 'react';
import {
  FaHome,
  FaInfoCircle,
  FaFilm,
  FaTv,
  FaPlay,
  FaStar,
  FaPhone,
} from 'react-icons/fa';
import { PiFilmSlateDuotone } from 'react-icons/pi';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdOutlineMenu } from 'react-icons/md';
import { BiMoviePlay } from 'react-icons/bi';
import Logo from '../assets/seinhtayfont.png';
import LanguageToggle from './LanguageToggle';
import { RiMovie2Line } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { CgFeed } from 'react-icons/cg';

const links = [
  { id: 'Home', icon: <FaHome className="mx-auto h-6 w-6" />, text: 'Home' },
  {
    id: 'About',
    icon: <FaInfoCircle className="mx-auto h-6 w-6" />,
    text: 'About',
  },
  {
    id: 'Library',
    icon: <PiFilmSlateDuotone className="mx-auto h-6 w-6" />,
    text: 'Library',
    submenu: [
      { id: 'WatchNow', text: 'Watch Now' },
      { id: 'CoProduced', text: 'Co-Produced' },
      { id: 'ComingSoon', text: 'Coming Soon' },
      { id: 'Movies', text: 'Movies' },
      { id: 'Series', text: 'Series' },
    ],
  },
  {
    id: 'Artists',
    icon: <FaStar className="mx-auto h-6 w-6" />,
    text: 'Artists',
  },
  {
    id: 'Activities',
    icon: <CgFeed className="mx-auto h-6 w-6" />,
    text: 'Activities',
  },
  {
    id: 'Contact',
    icon: <FaPhone className="mx-auto h-6 w-6" />,
    text: 'Contact',
  },
];

const Navbar = ({ onEnter, activeSection }) => {
  const [selectedLink, setSelectedLink] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [currLang, setCurrLang] = useState('EN');
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // const dropDownToggle = (id) => {
  //   if (id === "Library") {
  //     setDropDownOpen(!dropDownOpen);
  //     if (dropDownOpen === true) {
  //     }
  //   }
  // };

  useEffect(() => {
    setSelectedLink(activeSection);
  }, [activeSection]);

  // console.log(selectedLink);

  return (
    <div
      className={`flex items-center fixed w-full top-0 z-50 transition duration-300 ${
        onEnter || menuOpen ? 'bg-red-600' : 'bg-transparent'
      }`}
    >
      <div className="mr-auto w-48 md:w-72">
        <img src={Logo} alt="Sein Htay" />
      </div>
      <ul className="hidden md:flex w-auto items-center mr-2">
        {links.map(({ id, icon, text, submenu }) => (
          <li
            key={id}
            className="mx-1 w-16 h-16 cursor-pointer hidden md:block"
          >
            <a
              className="w-full"
              href={`#${id}`}
              // onClick={() => {
              //   dropDownToggle(id);
              //   // setSelectedLink(id);
              // }}
              onMouseOver={() => {
                if (id === 'Library') {
                  setDropDownOpen(true);
                }
              }}
              onMouseLeave={() => {
                if (id === 'Library') {
                  setDropDownOpen(false);
                }
              }}
            >
              <div
                className={`text-xl transition duration-400 h-full flex flex-col justify-center border-0 rounded-2xl ${
                  selectedLink === id
                    ? 'text-red-600 bg-white hover:scale-105'
                    : 'text-white hover:scale-125'
                }`}
              >
                <div
                  className={`transition duration-500  ${
                    selectedLink === id ? '' : 'translate-y-2.5'
                  }`}
                >
                  {icon}
                </div>
                <p
                  className={`text-center my-0 text-sm transition duration-500 ${
                    selectedLink === id
                      ? 'text-red-600 opacity-100'
                      : 'text-white opacity-0'
                  }`}
                >
                  {text}
                </p>
              </div>
            </a>
            {id === 'Library' && (
              <motion.ul
                className="relative top-2 right-16 w-52 bg-white rounded-2xl drop-shadow-lg overflow-hidden"
                initial={{
                  height: 0,
                  opacity: 0,
                  y: -20,
                }}
                animate={{
                  height: dropDownOpen ? 'auto' : 0,
                  opacity: dropDownOpen ? 1 : 0,
                  y: dropDownOpen ? 0 : -20,
                }}
                transition={{ duration: 0.35 }}
                onMouseEnter={() => {
                  setDropDownOpen(true);
                }}
                onMouseLeave={() => {
                  setDropDownOpen(false);
                }}
              >
                {submenu.map(({ id, text }) => (
                  <li
                    key={id}
                    className="block text-base text-white cursor-pointer text-center border-y border-transparent hover:scale-125 duration-300 active:bg-slate-100"
                    onClick={() => {
                      setDropDownOpen(false);
                    }}
                  >
                    <a
                      href={`#${id}`}
                      className="w-full flex justify-center items-center h-10"
                    >
                      <div className="w-full text-red-600">{text}</div>
                    </a>
                  </li>
                ))}
              </motion.ul>
            )}
          </li>
        ))}
        <li className="mx-1 my-auto w-20 cursor-pointer hidden md:block">
          <LanguageToggle currLang={currLang} setCurrLang={setCurrLang} />
        </li>
      </ul>
      <div className="md:hidden">
        {!menuOpen ? (
          <MdOutlineMenu
            className="h-10 w-10 text-white mr-2"
            onClick={toggleMenu}
          />
        ) : (
          <IoMdCloseCircle
            className="h-10 w-10 text-white mr-2"
            onClick={closeMenu}
          />
        )}
        {menuOpen && (
          <ul className="absolute top-12 md:top-24 right-0 w-full bg-red-600 rounded-b-2xl text-white">
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#Home`}
              >
                <p>Home</p>
                <div className="mr-2">
                  <FaHome />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#About`}
                onClick={() => setSelectedLink('About')}
              >
                <p>About</p>
                <div className="mr-2">
                  <FaInfoCircle />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#WatchNow`}
                onClick={() => setSelectedLink('WatchNow')}
              >
                <p>Watch Now</p>
                <div className="mr-2">
                  <FaPlay />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#CoProduced`}
                onClick={() => setSelectedLink('CoProduced')}
              >
                <p>Co-Produced</p>
                <div className="mr-2">
                  <FaFilm />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#ComingSoon`}
                onClick={() => setSelectedLink('ComingSoon')}
              >
                <p>Coming Soon</p>
                <div className="mr-2">
                  <FaTv />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#MoviesSeries`}
                onClick={() => setSelectedLink('MoviesSeries')}
              >
                <p>Movies</p>
                <div className="mr-2">
                  <BiMoviePlay />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#Series`}
                onClick={() => setSelectedLink('Series')}
              >
                <p>Series</p>
                <div className="mr-2">
                  <RiMovie2Line />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#Artists`}
                onClick={() => setSelectedLink('Artists')}
              >
                <p>Artists</p>
                <div className="mr-2">
                  <FaStar />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#Activities`}
                onClick={() => setSelectedLink('Activities')}
              >
                <p>Activities</p>
                <div className="mr-2">
                  <CgFeed />
                </div>
              </a>
            </li>
            <li
              className="block px-2 py-1 text-base text-white cursor-pointer active:bg-gray-100/30"
              onClick={() => {
                closeMenu();
              }}
            >
              <a
                className="flex justify-between items-center h-8"
                href={`#Contact`}
                onClick={() => setSelectedLink('Contact')}
              >
                <p>Contact</p>
                <div className="mr-2">
                  <FaPhone />
                </div>
              </a>
            </li>
            <li className="px-4 py-2 text-sm text-white cursor-pointer flex justify-center">
              <LanguageToggle
                className="w-full mx-auto"
                currLang={currLang}
                setCurrLang={setCurrLang}
              />
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Navbar;
