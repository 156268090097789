import { useTranslation } from 'react-i18next';
const FacebookFeed = () => {
  const { i18n, t } = useTranslation();
  return (
    <div className="flex flex-col min-h-screen justify-center pb-10">
      <h2
        className={`text-black text-center font-bold mb-4 md:mb-12 ${
          i18n.language === 'en'
            ? 'text-3xl md:text-4xl'
            : 'text-2xl md:text-3xl'
        }`}
      >
        {t('facebook_feed_title')}
      </h2>
      <div className="flex flex-wrap justify-center gap-10 w-full h-full">
        <iframe
          title="seinhtay"
          width="360"
          height="500"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fseinhtayproduction%3Fmibextid%3DLQQJ4d%26rdid%3DWCp5sblrIwH0fuUT%26share_url%3Dhttps%253A%252F%252Fwww.facebook.com%252Fshare%252F1A4HVeWPLb%252F%253Fmibextid%253DLQQJ4d&tabs=timeline&width=360&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1093372268341863"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
        <iframe
          title="htet"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100069781145370%26mibextid%3DLQQJ4d%26rdid%3DP9leptYZXnS6hbHZ%26share_url%3Dhttps%253A%252F%252Fwww.facebook.com%252Fshare%252F15f3Ax4i8A%252F%253Fmibextid%253DLQQJ4d%23&tabs=timeline&width=360&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1093372268341863"
          width="360"
          height="500"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </div>
    </div>
  );
};

export default FacebookFeed;
